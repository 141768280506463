// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-letter-js": () => import("/opt/build/repo/gatsby/src/templates/letter.js" /* webpackChunkName: "component---src-templates-letter-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/gatsby/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import("/opt/build/repo/gatsby/.cache/data.json")

